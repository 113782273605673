import React from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useLocation } from 'react-router-dom';
import paperTemplate from '../paperTemplate.jpg';


const queryClient = new QueryClient()


function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}




export default function Subject() {
  // const { course, semester, year } = useParams();
  const query = useQueryParams();

  const course = query.get("course").toLowerCase();
  const semester = query.get("semester");
  const year = query.get("year");

  return (
    <>


      <div className='p-3 m-1 mt-2'>
        <div className="row mt-2">


          <QueryClientProvider client={queryClient}>
            <Example course={course} semester={semester} year={year} />
          </QueryClientProvider>




        </div>
      </div>







    </>



  )
}


function Example(props) {
  const { isLoading, error, data } = useQuery('repoData', () =>
    fetch(`https://hptu-plus-study.pages.dev/api/${props.course}/${props.semester}/${props.year}/details.json`).then(res =>
      res.json()
    )
    , {
      cacheTime: 0
    })

  if (isLoading) return 'Loading...'

  if (error) return (
    <>

      <div className='text-danger h3'>
        This Content is in Queue. Please Check After Sometime. Msg us for Prioritize your Content.
      </div>

      <div className='text-danger h3'>
        Soory. The Question Paper you are Requesting is not available. If you have this content in future, Please Share with us.
      </div>

      <center>
        <a className='btn p-2 m-2 text-white bg-success border w-75'>
          <b>
            Whatsapp Us on 8351943122
          </b></a>
      </center>



    </>
  )

  return (
    <>


      {data.mainData.map((info) => (
        <>



          <button className="btn text-black shadow bg-secandory mt-2 border" onClick={() => { openImage(info.url) }}>
            <span className='row'>
              <span className="col-2">
                <img className="" src={paperTemplate} width="100px" height="80px" />
              </span>


              <div className='p-3 col-10 text-right'>
                <b>{info.name.toUpperCase()}</b>
                <br />
                {info.subjectcode.toUpperCase()}
              </div>
            </span>
          </button>



        </>


      ))}

      <center>
        <a className='btn p-2 m-2 text-white bg-success border w-75' >
          <b>
            Any Problem Whatsapp Us on 8351943122
          </b></a>
      </center>



    </>
  )
}

function openImage(string) {
  window.Android.openImage(string);
}

