import './App.css';
import Home from './pages/Home';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import QuestionPapers from './pages/QuestionPapers';
import Syllabus from './pages/Syllabus';
import Subject from './pages/Subject';
import Hpcet from './pages/Hpcet';

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes basename={'/'}>


      <Route path='/' element={<Home />} />
      <Route path='/question-papers' element={<QuestionPapers />} /> 
      <Route path='/syllabus' element={<Syllabus />} />
      <Route path='/hpcet' element={<Hpcet />} />





      <Route path={`${process.env.PUBLIC_URL}/subject`} element={<Subject />} />
    </Routes>
    
    </BrowserRouter>



    
    </>
  );
}

export default App;
