import React from 'react'

export default function Hpcet() {
    return (
        <div>
            <div className="m-4 p-5 text-center">


                <div className="mt-3 h5 w-100 text-center fw-bold">
                    Question Paper
                </div>

                <a className='btn bg-primary text-white mt-2' onClick={() => { openPDF("https://hptu-plus-study.pages.dev/api/hpcet/mca-2023.pdf")}} >
                    <b>
                        HPCET MCA 2023
                    </b>
                </a>

                <br />

                <a className='btn bg-primary text-white mt-2' onClick={() => { openPDF("https://hptu-plus-study.pages.dev/api/hpcet/mba-mba-th-2023.pdf")}} >
                    <b>
                        HPCET MBA/MBA Tourism 2023
                    </b>
                </a>


                <div className="mt-5 h5 w-100 text-center fw-bold">
                    Answer Key
                </div>

                <a className='btn bg-primary text-white mt-2' onClick={() => { openPDF("https://hptu-plus-study.pages.dev/api/hpcet/mca-2023-answer-key.pdf")}} >
                    <b>
                        HPCET MCA 2023
                    </b>
                </a>

                <br />

                <a className='btn bg-primary text-white mt-2' onClick={() => { openPDF("https://hptu-plus-study.pages.dev/api/hpcet/mba-mba-th-2023-answer-key.pdf")}} >
                    <b>
                        HPCET MBA and MBA (T & HM) 2023
                    </b>
                </a>

                <br />

                <a className='btn bg-primary text-white mt-2' onClick={() => { openPDF("https://hptu-plus-study.pages.dev/api/hpcet/btech-bpharma-2023-answer-key.pdf")}} >
                    <b>
                        HPCET B.Tech & B.Pharma 2023
                    </b>
                </a>

                <br />

            </div>
        </div>
    )
}


function openPDF(string) {
    window.Android.openPDF(string);
}