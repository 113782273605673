import React from 'react'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'




const queryClient = new QueryClient()

export default function Syllabus() {
    return (
        <>
            <Form />
        </>
    )
}

function Form() {

    return (
        <div className="m-4 p-5 text-center">

            <h3 className="text-danger">
                * Select Your Details
            </h3>

            <QueryClientProvider client={queryClient}>
                <Example />
            </QueryClientProvider>

            <br />

            <button className='btn text-white shadow bg-primary mt-4 border p-2' onClick={() => { openPDF(document.getElementById('course') !== null ? document.getElementById('course').value : null) }}>
                <b>
                    Click here
                </b>
            </button>

        </div>
    )
}




function openPDF(urlValue) {
    if (urlValue != null) {
        let url = "https://hptu-plus-study.pages.dev/api/syllabus" + urlValue;
        window.Android.openPDF(urlValue)
    }
}



function Example() {
    const { isLoading, error, data } = useQuery('repoData', () =>
        fetch("https://hptu-plus-study.pages.dev/api/syllabus/details.json").then(res =>
            res.json()
        )
    )

    if (isLoading) return 'Loading...'

    if (error) return (
        <>
            <div className='text-danger h2'>
                The Resource You are Requesting is not available. Please Contact the Website Administrator.
            </div>
        </>
    )

    return (
        <>


            <select id="course" className="mt-3 h5 w-100 text-center fw-bold" required>
                {data.map((i) => <option value={i.url}>{i.course}</option>)}
            </select>





        </>
    )
}