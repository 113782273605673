import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';


import { Carousel } from "react-bootstrap"
import React from "react"
import { Link } from "react-router-dom"
import imgLink1 from '../images/img1.jpg'




export default function Home() {
    return (
        <>
            <div className="container">

                <BannerShow />

                <h2 className="text-center text-white my-4 bg-orange p-2 border rounded">
                    <b>
                        Study Portal
                    </b>
                </h2>

                <div className="row gx-3 mt-2">
                    <Link className="col-4 btn text-primary" to='/hpcet'>
                        <div className="p-3 border rounded bg-white shadow text-center h6">
                            <StarBorderOutlinedIcon />
                            <br />
                            <b>
                                HPCET 2023
                            </b>

                        </div>
                    </Link>
                    <Link className="col-4 btn text-primary" to='/syllabus'>
                        <div className="p-3 border rounded bg-white shadow text-center h6">
                            <StarBorderOutlinedIcon />
                            <br />
                            <b>
                                Syllabus PDF
                            </b>

                        </div>
                    </Link>
                    <Link className="col-4 btn text-primary" to='/question-papers'>
                        <div className="p-3 border rounded bg-white shadow text-center h6">
                            <DocumentScannerOutlinedIcon />
                            <br />
                            <b>
                                Question Papers
                            </b>

                        </div>
                    </Link>

                </div>


                <div className="row gx-3 mt-2">

                    <a className="col-4 btn text-primary" onClick={()=>{openWebContent("https://allindialibrary.com/himachal-pradesh-technical-university/")}}>
                        <div className="p-3 border rounded bg-white shadow text-center h6">
                            <span className="material-symbols-outlined">
                                menu_book
                            </span><br />
                            <b>
                            Study Material
                            </b>
                        </div>
                    </a>

                </div>



            </div>
        </>
    )
}



function BannerShow() {
    return (
        <>


            <Carousel className='m-1 border border-warning border-2 p-2'>
                <Carousel.Item>
                    <img src="https://hptu-plus.pages.dev/api/banner-images/0.jpg" className="d-block w-100" alt="..." height="200px" />
                </Carousel.Item>
                <Carousel.Item>
                    <img src="https://hptu-plus.pages.dev/api/banner-images/0.jpg" className="d-block w-100" alt="..." height="200px" />
                </Carousel.Item>
                <Carousel.Item>
                    <img src="https://hptu-plus.pages.dev/api/banner-images/0.jpg" className="d-block w-100" alt="..." height="200px" />
                </Carousel.Item>
            </Carousel>


        </>
    )
}



function openPDF(string) {
    window.Android.openPDF(string);
}

function openWebContent(string){
    window.Android.openWebContent(string);
}