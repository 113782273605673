import React, { useState } from "react";
import { Link } from "react-router-dom";






export default function QuestionPapers() {



    return (
        <>
            {/* <NavBar /> */}
            <HtmlForm />

        </>
    )
}




function HtmlForm() {

    let courses = ["MCA", "PG-Diploma-Yoga", "MSc-Physics", "MBA", "MSc-Environment-Science","MBA-Tourism-Hospitality","B-Pharma-PCI","B-Tech-CS","B-Tech-EC","B-Tech-EE","B-Tech-EEE","B-Tech-ME","B-Tech-TE", "BSc-HMCT"]; //courses added here
    let semester = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];
    let year = ["2022"]; //year 


    let [url, newUrl] = useState("/subject/?course=mca&semester=1st&year=2022");

    function updateState(){
        let selectedCourse = document.getElementById('course').value.lenght !== 0 ? document.getElementById('course').value : "";
        let selectedSemester = document.getElementById('semester').value.lenght !== 0 ? document.getElementById('semester').value : "";
        let selectedYear = document.getElementById('year').value.lenght !== 0 ? document.getElementById('year').value : "";

        newUrl("/subject?course=" + selectedCourse + "&semester=" + selectedSemester + "&year=" + selectedYear);
    }




    return (
        <>
            <div className="m-4 p-5 text-center">

                <h3 className="text-danger">
                    * Select Your Details
                </h3>

                {/* <datalist id="suggestions">
                    {courses.map((i) => <option value={i.toLowerCase()}>{i}</option>)}
                </datalist>
                <input id="course" className="mt-3 h5 w-100 text-center fw-bold" autoComplete="on" onInput={updateState} list="suggestions" placeholder="Course" required /> */}

                <select id="course" className="mt-3 h5 w-100 text-center fw-bold" onInputCapture={()=>{updateState()}} required>
                {courses.map((i) => <option value={i}>{i}</option>)}
                </select>

                <br />


                <select id="semester" className="mt-3 h5 w-100 text-center" required onInputCapture={()=>{updateState()}}>
                    {semester.map((i) => <option value={i}>{i}</option>)}
                </select>

                <br />


                <select id="year" className="mt-3 h5 w-100 text-center" required onInputCapture={()=>{updateState()}}>
                    {year.map((i) => <option value={i}>{i}</option>)}
                </select>


                <br />


                <Link className="w-75 mt-5 btn text-white bg-primary rounded" id="linkX" onInputCapture={()=>{updateState()}} to={url}>
                    Search
                </Link>


                <a className="w-75 mt-3 btn text-white bg-primary rounded" onClick={()=>{window.Android.openWhatsapp()}}>
                    Upload Question Papers
                </a>







            </div>
        </>
    )
}


function openWebContent(string){
    window.Android.openWebContent(string);
}




// {names.filter(name => name.includes('J')).map(filteredName => (
//     <li>
//       {filteredName}
//     </li>
//   ))}